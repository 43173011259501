<template>
  <footer class="footer container-fluid p-0">
    <svg class="curve_path" xmlns="http://www.w3.org/2000/svg" viewBox="0 1 1440 320">
      <path fill="currentColor" fill-opacity="1" d="M0,160L80,170.7C160,181,320,203,480,208C640,213,800,203,960,176C1120,149,1280,107,1360,85.3L1440,64L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
    </svg>
    <div class="container-lg mx-auto">
      <div class="row pl-3 py-2 d-flex justify-content-around">
        <div class="col-sm-6 col-xs-12 col_one d-none d-lg-flex flex-column pr-5">
          <h5 class="text-uppercase">Logo</h5>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non mollitia officiis eveniet numquam aspernatur minima cumque error.</p>
        </div>

        <div class="col-lg-2 col-sm-6 col-xs-6 pl-sm-5 p-lg-0">
          <h6 class="text-uppercase">Wakaru.ORG</h6>
          <ul class="list-unstyled">
            <li><router-link :to="{name:'about'}">About Us</router-link></li>
            <li><router-link :to="{name:'contact'}">Contact Us</router-link></li>
            <li><router-link :to="{name:'about'}">FAQ</router-link></li>
            <li><router-link :to="{name:'policy'}">Privacy Policy</router-link></li>
            <li><router-link :to="{name:'tou'}">Terms of use</router-link></li>
          </ul>
        </div>

        <hr class="clearfix w-100 d-sm-none pb-1" />

        <div class="follow_us col-lg-4 col-sm-6 col-xs-6">
          <h6 class="text-uppercase">Follow Us</h6>
          <router-link class="brandicon facebook" :to="{name:'about'}"><FacebookIcon width="2.5rem" height="2.5rem" /></router-link>
          <router-link class="brandicon twitter" :to="{name:'about'}"><TwitterIcon width="2.5rem" height="2.5rem" /></router-link>
          <router-link class="brandicon instagram" :to="{name:'about'}"><InstagramIcon width="2.5rem" height="2.5rem" /></router-link>
          <router-link class="brandicon whatsapp" :to="{name:'about'}"><WhatsappIcon width="2.5rem" height="2.5rem" /></router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FacebookIcon from 'mdi-vue/Facebook';
import TwitterIcon from 'mdi-vue/Twitter';
import InstagramIcon from 'mdi-vue/Instagram';
import WhatsappIcon from 'mdi-vue/Whatsapp';

export default {
  name: 'SectionFooter',
  components: {
    FacebookIcon, TwitterIcon, InstagramIcon, WhatsappIcon,
  },

};
</script>

<style lang='stylus'>

:root
  --color_facebook: #3b5998
  --color_twitter: #00acee
  --color_instagram: #3f729b
  --color_whatsapp: #25d366

.footer
  position relative
  .curve_path
    margin-bottom -2rem
    @media $mq_lg
      margin-bottom -3rem
    @media $mq_xl
      margin-bottom -4rem
    @media $mq_wd
      margin-bottom -4rem
    @media $mq_xwd
      margin-bottom -5rem
  .follow_us
    .brandicon
      margin-right 0.5rem
    .facebook
      color var(--color_facebook)
    .twitter
      color var(--color_twitter)
    .instagram
      color var(--color_instagram)
    .whatsapp
      color var(--color_whatsapp)

</style>
